import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Route, Routes, useLocation
} from 'react-router-dom';
import './App.css';
import loadingGif from './loading.gif';

const domainName = "https://artshumor.shop";
const CSS = {
  display: "flex",
  justifyContent:"center",
  alignItems: 'center',
  height: "100vh"
}

const Redirect = () => {
  
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(`${domainName}${location.pathname}`);
    },1000);
  },[location])

  return(
      <div style={CSS}>
        <img src={loadingGif} alt="" />
      </div>
  )
  
}

function App() {
  return(
    <Router>
      <Routes>
        <Route path='*' element={<Redirect/>}/>
      </Routes>
    </Router>
  )
}

export default App